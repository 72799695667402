import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container container">
            <h1 className="footer__title">Ashutosh</h1>
            <ul className="footer__list">
                <li>
                    <a href="#about" className="footer__link">About</a>
                </li>
                <li>
                    <a href="#portfolio" className="footer__link">Projects</a>
                </li>
                <li>
                    <a href="#testimonials" className="footer__link">Testimonials</a>
                </li>
            </ul>

            <div className="footer__social">
            <a href="https://github.com/ashut0sh-tiwari" className="footer__social-link" rel='noreferrer' target='_blank'>
            <i class='bx bxl-github'></i>
        </a>
     
        <a href="https://www.linkedin.com/in/ashut0sh-tiwari/" className="footer__social-link" rel='noreferrer' target='_blank'>
        <i class='bx bxl-linkedin-square'></i>
            
        </a>
            </div>
            <span className="footer__copy">&#169; Ashutosh. All rights reserved</span>
        </div>
    </footer>
  )
}

export default Footer